@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@650&display=swap');

html {
  background-color: black;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: 'Orbitron', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  // font-size: 120%;
  letter-spacing: 1px;
}

a,
a:visited,
a:active {
  color: darkturquoise;
}

a:hover {
  color: steelblue;
}

:root {
  --snap-yellow: #fffc00;
  --gradient-1: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
}

.screen-saver {
  background-color: #111;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // position: relative;
  @media screen and (max-width: 500px) {
  }
  .clock {
    bottom: 100;
    z-index: 1001;
    position: absolute;
    left: 100;
    top: 0;
    right: 0;
    color: white;
    margin-right: 10px;
    margin-top: 10px;
    font-family: 'Orbitron', Arial, Helvetica, sans-serif;
    .day {
      font-size: 40px;
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
    .time {
      font-size: 60px;
      @media screen and (max-width: 500px) {
        font-size: 30px;
      }
    }
  }
}

.body-wrapper {
  background: url('/assets/img/gradient-desktop.jpg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  z-index: 1000;
  top: 0;
  filter: grayscale(1);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: translate3d(0, 0, 0);
}

.shawn-name {
  text-align: center;
  padding: 0px 10px;
}

.headline {
  text-align: center;
  margin-top: 0;
  padding: 0 5px;
}

.shawn-name img {
  max-width: 100%;
}

.container {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.page-container {
  width: 98%;
  margin: 1%;
  min-height: 90vh;
}

.text-center {
  text-align: center;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.hire-wrapper,
.projects-wrapper,
.cv-wrapper,
.freelance-wrapper {
  padding: 30px 5px;
}

.job {
  margin: 10px 5px;
}

.dark-mode .job {
  background: transparent;
  color: whitesmoke;
  border: solid 2px whitesmoke;
}
.dark-mode .listbox * {
  color: whitesmoke;
}

.dark-mode p {
  // color: black;
}

.dark-mode .tech-stack :before {
  filter: brightness(0.5);
}
.dark-mode {
  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background: transparent;
    border: solid 2px whitesmoke;
  }
  .mat-step-text-label {
    color: white;
  }
}

@media (prefers-color-scheme: dark) {
}

html {
  --text-color-normal: #0a244d;
  --text-color-light: #8cabd9;
}

html[data-theme='dark'] {
  --text-color-normal: hsl(210, 10%, 62%);
  --text-color-light: hsl(210, 15%, 35%);
  --text-color-richer: hsl(210, 50%, 72%);
  --text-color-highlight: hsl(25, 70%, 45%);
}

@media screen and (max-width: 500px) {
  h2 {
    text-align: center;
  }
}

// Override Tooltip Placement
.cdk-overlay-container {
  z-index: 2001;
}

// Icon Sprites
.tech-stack {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    margin: 5px;
    align-items: center;
    &:hover::before {
      // background-image: url(/assets/img/stack-sprite-g.png) !important;
      // filter: initial;
    }
    .tech-name {
      font-family: 'VT323', monospace;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: flex;
      margin-left: 5px;
      width: 83px;
      word-break: break-all;
      @media screen and (min-width: 500px) {
        width: 100px;
      }
      @media screen and (max-width: 500px) {
        margin-left: 0;
        margin-top: 10px;
        justify-content: center;
        width: 100%;
      }
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
  }

  :before {
    border-radius: 6px;
    height: 56px;
    width: 56px;
    filter: grayscale(1);
  }

  @media screen and (max-width: 500px) {
    justify-content: center;
  }
}

.icon-stack-swift:before,
.icon-stack-heroku:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-squarespace:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-slack:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-vscode:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-firebase:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-typescript:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-airtable:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-zapier:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-git:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-lensstudio:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -488px;
  width: 56px;
  height: 56px;
}
.icon-stack-ionicjs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -549px;
  width: 56px;
  height: 56px;
}
.icon-stack-shopify:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-access:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-angularjs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-aspnet:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-backbonejs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-c:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-caffe:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-cakephp:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-cassandra:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-codeigniter:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-cplusplus:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-d3js:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-db2:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-django:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-emberjs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-express:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-fallback:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-filemaker:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-flask:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-flex:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-flux:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-golang:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-nestjs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-hadoop:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-hbase:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-hive:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-informix:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-java:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-javascript:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-jquery:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-jqueryui:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-jsf:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-kotlin:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-laravel:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-mariadb:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-memcached:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-meteor:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-microsoftsqlserver:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-modemizr:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-mongodb:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-mysql:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-neo4j:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-net:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-nodejs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-oracle:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-parse:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-perl:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-php:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-play:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-postgresql:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-python:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-r:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-react:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-redis:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-redux:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-ruby:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-rubyonrails:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-sailsjs:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-saphana:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-scala:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-spark:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-spring:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-sql:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-sqlite:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-symfony:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-tensorflow:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-teradata:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-theano:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-torch:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-twitterbootstrap:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-zend:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-zepto:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-affinitydesigner:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-aha:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-asana:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-axure:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-balsamiq:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-basecamp:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-confluence:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-fallback:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-googleanalytics:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-illustrator:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-invision:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-jira:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-kissmetrics:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-microsoftproject:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-mixpanel:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-optimizely:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-photoshop:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-pivotaltracker:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-piwik:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-pixelmator:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-sketch:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-smartsheet:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-solidworks:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-trello:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-uxpin:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-wrike:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-act:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-adobecampaign:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-aweber:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-campaignmonitor:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-constantcontact:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-drupal:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-fallback:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -183px;
  width: 56px;
  height: 56px;
}

.icon-stack-fullcontact:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -244px;
  width: 56px;
  height: 56px;
}

.icon-stack-hubspot:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -305px;
  width: 56px;
  height: 56px;
}

.icon-stack-infusionsoft:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -366px;
  width: 56px;
  height: 56px;
}

.icon-stack-insightly:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -427px;
  width: 56px;
  height: 56px;
}

.icon-stack-intercom:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -488px;
  width: 56px;
  height: 56px;
}

.icon-stack-joomla:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -549px;
  width: 56px;
  height: 56px;
}

.icon-stack-mailchimp:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: 0px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-mailgun:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -61px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-mandrill:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -122px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-marketo:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -183px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-microsoftdynamics:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -244px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-mixrank:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -305px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-oracleeloqua:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -366px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-pipedrive:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -427px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-rapportive:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -488px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-sage:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -549px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-salesforce:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -610px -610px;
  width: 56px;
  height: 56px;
}

.icon-stack-sendgrid:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px 0px;
  width: 56px;
  height: 56px;
}

.icon-stack-tumblr:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -61px;
  width: 56px;
  height: 56px;
}

.icon-stack-wordpress:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -122px;
  width: 56px;
  height: 56px;
}

.icon-stack-zoho:before {
  display: block;
  content: ' ';
  background-image: url(/assets/img/stack-sprite.png);
  background-position: -671px -183px;
  width: 56px;
  height: 56px;
}

.btn-grad-1 {
  background-image: linear-gradient(
    to right,
    #e55d87 0%,
    #5fc3e4 51%,
    #e55d87 100%
  );
}
.btn-grad-1:hover {
  background-position: right center;
}

.btn-grad-2 {
  background-image: linear-gradient(
    to right,
    #da22ff 0%,
    #9733ee 51%,
    #da22ff 100%
  );
}
.btn-grad-2:hover {
  background-position: right center;
}

.btn-grad-3 {
  background-image: linear-gradient(
    to right,
    #2b5876 0%,
    #4e4376 51%,
    #2b5876 100%
  );
}
.btn-grad-3:hover {
  background-position: right center;
}

.btn-grad-4 {
  background-image: linear-gradient(
    to right,
    #00d2ff 0%,
    #3a7bd5 51%,
    #00d2ff 100%
  );
  background-position: center center;
}
.btn-grad-4:hover {
  background-position: right center;
}

.btn-grad-5 {
  background-image: linear-gradient(
    to right,
    #c9ffbf 0%,
    #ffafbd 51%,
    #c9ffbf 100%
  );
}
.btn-grad-5:hover {
  background-position: right center;
}
.cta {
  width: 260px;
  border-radius: 60px !important;
}
.lil-gui {
  z-index: 3005 !important;
}

// Dot Icons
.doticon {
  height: 80px;
  width: 80px;
  position: relative;
  svg-icon {
    position: absolute;
    inset: 0;
    path {
      fill: #fff;
    }
  }
  canvas {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    mask: var(--icon);
    -webkit-mask: var(--icon); /* For Safari */
    // background: red; /* Replace with your gradient animation */
    // opacity: var(--active, 0);
    transition: opacity 0.2s;
  }
}

// anmiated gradient class can be applied to svg fill for a pshychadelic affect
$colors: #e55d87, #5fc3e4, #f093fb, #f5576c, #3ae374, #673ab7, #ff5722, #009688,
  #ff9800, #2196f3;

@function shuffle($list) {
  $length: length($list);
  $bucket: ();

  @while $length > 0 {
    $pick: random($length);

    // Check if the index is valid before accessing the list
    @if $pick <= length($list) {
      $picked: nth($list, $pick);
      $list: remove-nth($list, $pick);
      $bucket: append($bucket, $picked);
      $length: $length - 1;
    }
  }

  @return $bucket;
}

// Function to zip two lists together
@function zip($list1, $list2) {
  $zipped: ();
  $length: min(length($list1), length($list2));

  @for $i from 1 through $length {
    $zipped: append($zipped, (nth($list1, $i), nth($list2, $i)));
  }

  @return $zipped;
}

@each $index in 1 through 10 {
  $randomized-colors: shuffle($colors);
  $percentages: 0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100%;

  @keyframes gradient-#{$index} {
    @each $color, $percent in zip($randomized-colors, $percentages) {
      #{$percent} {
        fill: $color;
      }
    }
  }

  .gradient-animated-#{$index}s svg path {
    animation: gradient-#{$index} 5s ease infinite;
  }
}
